
//******API CONSTANTS********//

export const TEST_API_URL = 'https://testapi.saures.ru'
export const REAL_API_URL = 'https://api.saures.ru'

let host = window.location.host

switch (host) {
    case "lk.saures.ru" :
        localStorage.setItem("API_URL", "https://api.saures.ru")
        break
    case "test.saures.ru" :
        localStorage.setItem("API_URL", "https://testapi.saures.ru")
        break
    default:
        localStorage.setItem("API_URL", "https://testapi.saures.ru")
        break
}

// localStorage.setItem("API_URL", "http://api.saures.ru")

export const ROOT_URL = localStorage.getItem("API_URL")
const VERSION_API = '/1.0'

export const LOGIN_URL_PATH = ROOT_URL + VERSION_API + '/login'
export const REGISTER_PATH = ROOT_URL + VERSION_API + "/user/register"
export const RECOVERY_PASSWORD_PATH = ROOT_URL + VERSION_API + "/user/recover"
export const PROFILE_URL_PATH = ROOT_URL + VERSION_API + '/user/profile'
export const INFOBLOCK_URL_PATH = ROOT_URL + VERSION_API + '/user/infoblocks'
export const OBJECTS_URL_PATH = ROOT_URL + VERSION_API + '/user/objects'
export const DELETE_OBJECT_PATH = ROOT_URL + VERSION_API + '/object/remove'
export const GET_TARIFFS_PATH = ROOT_URL + VERSION_API + '/object/tariffs'
export const GET_OBJECT_ITEM = ROOT_URL + VERSION_API + '/object/get'
export const GET_SERVICES_PATH = ROOT_URL + VERSION_API + '/object/services'
export const GET_PAYMENTS_PATH = ROOT_URL + VERSION_API + '/object/transactions'
export const PAYMENT_CREATE_PATH = ROOT_URL + VERSION_API + '/payment/create';
export const DELETE_METER_PATH =  ROOT_URL + VERSION_API + "/meter/remove"
export const GRAPHIC_METER_PATH =  ROOT_URL + VERSION_API + "/meter/get"
export const UPDATE_CONTROLLER_PATH = ROOT_URL + VERSION_API + "/sensor/settings"
export const UPDATE_METER_PATH = ROOT_URL + VERSION_API + "/meter/save"
export const COMMAND_SENSOR_PATH = ROOT_URL + VERSION_API + "/meter/control"
export const COMMAND_SENSOR_MOVE_PATH = ROOT_URL + VERSION_API + "/sensor/move"
export const COMMAND_SENSOR_DELETE_PATH = ROOT_URL + VERSION_API + "/sensor/delete"
export const COMMAND_SENSOR_CLEAR_PATH = ROOT_URL + VERSION_API + "/sensor/clear"
export const JOURNAL_URL_PATH = ROOT_URL + VERSION_API + '/object/journal'
export const METERS_URL_PATH  = ROOT_URL + VERSION_API + '/object/meters'
export const CONTROLLER_SETTINGS_URL_PATH  = ROOT_URL + VERSION_API + '/sensor/settings'
export const SCHEDULERS_URL_PATH  = ROOT_URL + VERSION_API + '/object/schedule'
export const TEMPLATES_URL_PATH  = ROOT_URL + VERSION_API + '/schedule/templates'
export const TEMPLATE_URL_PATH  = ROOT_URL + VERSION_API + '/schedule/template'
export const CREATE_OBJECT_PATH = ROOT_URL + VERSION_API + "/object/add"
export const NOTICES_URL_PATH  = ROOT_URL + VERSION_API + '/object/notice'
export const SUPPORT_URL_PATH  = ROOT_URL + VERSION_API + '/object/support'
export const RELEASE_NOTES_PATH  = ROOT_URL + VERSION_API + '/firmware/release-notes'
export const OBJECT_ACCESS_PATH  = ROOT_URL + VERSION_API + '/object/access'
export const GET_SENSOR_URL_PATH  = ROOT_URL + VERSION_API + "/sensor/add"
export const GET_SENSOR_CHANNELS_URL_PATH  = ROOT_URL + VERSION_API + "/sensor/channels"
export const GET_METER_CHANNELS_URL_PATH  = ROOT_URL + VERSION_API + "/meter/settings"
export const GET_CONTROLLER_BATTERY_URL_PATH  = ROOT_URL + VERSION_API + "/sensor/battery"

//******APPLICATION CONSTANTS********//

export const USER_LOGIN = 'USER_LOGIN'
export const USER_PASSWORD = 'USER_PASSWORD'
export const SID = 'SID'
export const OBJECT_ID = 'OBJECT_ID'
export const OBJECT_ADRESS = 'OBJECT_ADRESS'
export const OBJECTS = 'OBJECTS'
export const OBJECT_ITEM = 'OBJECT_ITEM'
export const METER_ID = 'METER_ID'
export const CONTROLLER = 'CONTROLLER'
export const METER_ITEM = 'METER_ITEM'
export const SENSOR_SETTINGS = 'SENSOR_SETTINGS'
export const NEED_GET_METERS = 'NEED_GET_METERS'
export const SCHEDULER_RECEIVER = 'SCHEDULER_RECEIVER'
export const JOURNAL_PAGE = 1
export const JOURNAL_STEP = 10
export const PAYMENT_PAGE = 1
export const PAYMENT_STEP = 50
export const SID_LENGTH = 36
export const ABSOLUTE_GRAPHIC = "ABSOLUTE_GRAPHIC"
export const CONTROLLER_READOUT = "CONTROLLER_READOUT"
export const EVENTS_ID = "EVENTS_ID"
export const GRAPHICS_ID = "GRAPHICS_ID"
export const METERS_ID = "METERS_ID"
export const READINGS_ID = "READINGS_ID"
export const SMALL_METER = "SMALL_METER"
export const SEARCH_DATE = "SEARCH_DATE"
export const EVENTS_ARRAY = "EVENTS_ARRAY"
export const GRAPHICS_ARRAY = "GRAPHICS_ARRAY"
export const USER_TARIFF = "USER_TARIFF"
export const METERS_ARRAY = "METERS_ARRAY"
export const ALL_SENSORS_ARRAY = "ALL_SENSORS_ARRAY"
export const DEMO_LOGIN = 'demo@saures.ru'
export const DEMO_PASSWORD = 'demo'
export const TECHNICAL_WORKS = 'Внимание! На сайте ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.'
export const APP_VER = 'v.3.3.5'
export const DASHBOARD_PAGE = "/dashboard"
export const SUPERVISOR_LOGIN = "SUPERVISOR"
export const SUPERVISOR_PASSWORD = "SUPERVISOR"
export const ROLE_LOGIN = "ROLE_COMPANY"
export const ROLE_PASSWORD = "ROLE_COMPANY"
export const APP_VERSION = "appVer"
export const SHOW_ALERT_JOURNAL = "SHOW_ALERT_JOURNAL"
export const ROLE = "ROLE"

// YOOKASSA

export const TEST_CARD = "5555555555554477"
export const SHOP_ID = "631022"
export const GATEWAY_ID = "YOUR_GATEWAY_ID"
