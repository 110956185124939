export const isMeter = (typeCode) => {
    const meterTypes = [1, 2, 3, 7, 8, 11, 12, 13];
    return meterTypes.includes(typeCode);
}

export const isMeasuringSensor = (typeCode) => {
    const mSensorTypes = [5, 14, 15];
    return mSensorTypes.includes(typeCode);
}

export const isContactSensor = (typeCode) => {
    const cSensorTypes = [4, 9];
    return cSensorTypes.includes(typeCode);
}

export const isVirtualDevice = (typeCode) => {
    const virtualTypes = [1000, 1001, 1003];
    return virtualTypes.includes(typeCode);
}

export const compareVersionHigher = (currentVer, minVer) => {

    const currentParts = currentVer.split('.').map(Number);
    const minParts = minVer.split('.').map(Number);

    for (let i = 0; i < Math.max(currentParts.length, minParts.length); i++){
        const current = currentParts[i] || 0;
        const min = minParts[i] || 0;
        if (current !== min) {
            return current > min ? true : false;
        }
    }
    return true;
}